"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mySavillsTypes = exports.getRequestCancelType = exports.getRequestEndType = exports.getFailureType = exports.getSuccessType = exports.getRequestType = void 0;
var types = {
    START_SESSION: 'START_SESSION',
    SESSION_DATA_LOADED: 'SESSION_DATA_LOADED',
    SET_PAGE_DATA: 'SET_PAGE_DATA',
    NEXT_ROUTE_CHANGE_BEGIN: 'NEXT_ROUTE_CHANGE_BEGIN',
    NEXT_ROUTE_CHANGE: 'NEXT_ROUTE_CHANGE',
    SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
    SHOW_LOGIN_MODAL: 'SHOW_LOGIN_MODAL',
    HIDE_LOGIN_MODAL: 'HIDE_LOGIN_MODAL',
    HIDE_SEARCH_PANEL: 'HIDE_SEARCH_PANEL',
    TOGGLE_SEARCH_PANEL: 'TOGGLE_SEARCH_PANEL',
    SET_VISIBLE_LOCATION_SECTION: 'SET_VISIBLE_LOCATION_SECTION',
    SELECT_SEARCH_LOCATION: 'SELECT_SEARCH_LOCATION',
    DESELECT_SEARCH_LOCATION: 'DESELECT_SEARCH_LOCATION',
    DESELECT_ALL_SEARCH_LOCATIONS: 'DESELECT_ALL_SEARCH_LOCATIONS',
    TOGGLE_LOCATION_SELECTIONS_VIEW: 'TOGGLE_LOCATION_SELECTIONS_VIEW',
    SET_SEARCH_LOCATIONS: 'SET_SEARCH_LOCATIONS',
    SEARCH_BY_URL_START: 'SEARCH_BY_URL_START',
    SEARCH_BY_URL_SUCCESS: 'SEARCH_BY_URL_SUCCESS',
    SEARCH_BY_URL_ERROR: 'SEARCH_BY_URL_ERROR',
    SEARCH_BY_URL_END: 'SEARCH_BY_URL_END',
    LIST_COUNTRIES_START: 'LIST_COUNTRIES_START',
    GET_COUNTRIES_LIST: 'GET_COUNTRIES_LIST',
    LIST_REGIONS_START: 'LIST_REGIONS_START',
    GET_REGIONS_LIST: 'GET_REGIONS_LIST',
    GET_USER_LOCATION_START: 'GET_USER_LOCATION_START',
    GET_USER_LOCATION_SUCCESS: 'GET_USER_LOCATION_SUCCESS',
    GET_USER_LOCATION_ERROR: 'GET_USER_LOCATION_ERROR',
    GET_USER_LOCATION_END: 'GET_USER_LOCATION_END',
    VIEW_REGION_LIST: 'VIEW_REGION_LIST',
    TOGGLE_REGION_SELECTION: 'TOGGLE_REGION_SELECTION',
    TOGGLE_COUNTRY_LIST: 'TOGGLE_COUNTRY_LIST',
    TOGGLE_SORT_ORDER_LIST: 'TOGGLE_SORT_ORDER_LIST',
    SET_SEARCH_CONTEXT: 'SET_SEARCH_CONTEXT',
    SELECT_PRIMARY_FILTER: 'SELECT_PRIMARY_FILTER',
    SELECT_FILTER_VALUE: 'SELECT_FILTER_VALUE',
    SET_MULTIPLE_FILTER_VALUES: 'SET_MULTIPLE_FILTER_VALUES',
    SET_VISIBLE_FILTER_CONTROL: 'SET_VISIBLE_FILTER_CONTROL',
    RESET_CATEGORY_TYPE: 'RESET_CATEGORY_TYPE',
    CHANGE_CATEGORY_TYPE: 'CHANGE_CATEGORY_TYPE',
    CHANGE_REGIONAL_SEARCH: 'CHANGE_REGIONAL_SEARCH',
    SET_REGIONAL_SEARCH: 'SET_REGIONAL_SEARCH',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    CURRENCY_CHANGE: 'CURRENCY_CHANGE',
    CURRENCY_VALUES: 'CURRENCY_VALUES',
    CURRENCY_VALUES_END: 'CURRENCY_VALUES_END',
    CURRENCY_VALUES_PENDING: 'CURRENCY_VALUES_PENDING',
    GET_FILTER_ANALYTICS: 'GET_FILTER_ANALYTICS',
    GET_FILTER_ANALYTICS_BASE: 'GET_FILTER_ANALYTICS_BASE',
    GET_DATA_ANALYSE_COUNT: 'GET_DATA_ANALYSE_COUNT',
    SEARCH_INPUT_CHANGE: 'SEARCH_INPUT_CHANGE',
    SEARCH_PROPERTIES_START: 'SEARCH_PROPERTIES_START',
    SEARCH_PROPERTIES_SUCCESS: 'SEARCH_PROPERTIES_SUCCESS',
    SEARCH_PROPERTIES_ERROR: 'SEARCH_PROPERTIES_ERROR',
    SEARCH_PROPERTIES_END: 'SEARCH_PROPERTIES_END',
    SEARCH_LOAD_SAVED: 'SEARCH_LOAD_SAVED',
    MAP_UPDATE_START: 'MAP_UPDATE_START',
    MAP_UPDATE_END: 'MAP_UPDATE_END',
    MAP_AREA_ADD: 'MAP_AREA_ADD',
    MAP_RESIZE_RADIUS: 'MAP_RESIZE_RADIUS',
    MAP_RESIZE_AREA_SET: 'MAP_RESIZE_AREA_SET',
    MAP_RESIZE_AREA_INSERT: 'MAP_RESIZE_AREA_INSERT',
    MAP_RESIZE_AREA_REMOVE: 'MAP_RESIZE_AREA_REMOVE',
    MAP_RENAME_AREA: 'MAP_RENAME_AREA',
    MAP_PINS_ADD: 'MAP_PINS_ADD',
    MAP_PINS_RESET: 'MAP_PINS_RESET',
    MAP_BOUNDS_SET: 'MAP_BOUNDS_SET',
    MAP_CONTEXT_SET: 'MAP_CONTEXT_SET',
    MAP_CENTER_SET: 'MAP_CENTER_SET',
    MAP_SIZE_SET: 'MAP_SIZE_SET',
    MAP_ZOOM_SET: 'MAP_ZOOM_SET',
    MAP_SHOW_POI: 'MAP_SHOW_POI',
    MAP_HIDE_POI: 'MAP_HIDE_POI',
    MAP_INFOBOX_SHOW: 'MAP_INFOBOX_SHOW',
    MAP_INFOBOX_HIDE: 'MAP_INFOBOX_HIDE',
    MAP_INFOBOX_CLICK: 'MAP_INFOBOX_CLICK',
    MAP_AREA_REQUEST_START: 'MAP_AREA_REQUEST_START',
    MAP_AREA_REQUEST_END: 'MAP_AREA_REQUEST_END',
    MAP_PROPERTY_DETAIL_BOUNDS_SET: 'MAP_PROPERTY_DETAIL_BOUNDS_SET',
    MAP_INIT: 'MAP_INIT',
    GET_MAP_AREA: 'GET_MAP_AREA',
    GET_MAP_PINS: 'GET_MAP_PINS',
    CALL_GENERATE_URL: 'CALL_GENERATE_URL',
    PROPERTY_TRACKING_CLICK: 'PROPERTY_TRACKING_CLICK',
    TOGGLE_PROPERTY_CONTACT_MODAL: 'TOGGLE_PROPERTY_CONTACT_MODAL',
    TOGGLE_PROPERTY_ARRANGE_VIEWING_MODAL: 'TOGGLE_PROPERTY_ARRANGE_VIEWING_MODAL',
    TOGGLE_PROPERTY_SHARE_MODAL: 'TOGGLE_PROPERTY_SHARE_MODAL',
    UPDATE_CURRENT_PAGE: 'UPDATE_CURRENT_PAGE',
    UPDATE_SORT_ORDER: 'UPDATE_SORT_ORDER',
    RESET_PROPERTY_DETAILS: 'RESET_PROPERTY_DETAILS',
    RESET_NEW_DEVELOPMENTS: 'RESET_NEW_DEVELOPMENTS',
    API_ERROR_OCCURRED: 'API_ERROR_OCCURRED',
    CONTACT_AGENT: 'CONTACT_AGENT',
    SUBMIT_CONTACT_AGENT: 'SUBMIT_CONTACT_AGENT',
    STORE_CONTACT_AGENT_FORM: 'STORE_CONTACT_AGENT_FORM',
    ARRANGE_VIEWING: 'ARRANGE_VIEWING',
    SUBMIT_ARRANGE_VIEWING: 'SUBMIT_ARRANGE_VIEWING',
    STORE_ARRANGE_VIEWING_FORM: 'STORE_ARRANGE_VIEWING_FORM',
    APPLY_SESSION_FORM_DATA: 'APPLY_SESSION_FORM_DATA',
    UPDATE_PROPERTIES_TO_SHOW_MODE: 'UPDATE_PROPERTIES_TO_SHOW_MODE',
    ACCOUNT_REGISTER: 'ACCOUNT_REGISTER',
    ACCOUNT_LOGIN: 'ACCOUNT_LOGIN',
    ACCOUNT_RESET_PASSWORD: 'ACCOUNT_RESET_PASSWORD',
    ACCOUNT_CHANGE_RESET_PASSWORD: 'ACCOUNT_CHANGE_RESET_PASSWORD',
    ACCOUNT_UNSUBSCRIBE: 'ACCOUNT_UNSUBSCRIBE',
    ACCOUNT_UNSUBSCRIBE_RESET: 'ACCOUNT_UNSUBSCRIBE_RESET',
    ACCOUNT_CHANGE_PASSWORD: 'ACCOUNT_CHANGE_PASSWORD',
    ACCOUNT_LOGIN_CLEAR_FORM: 'ACCOUNT_LOGIN_CLEAR_FORM',
    ACCOUNT_LOGOUT: 'ACCOUNT_LOGOUT',
    ACCOUNT_HIDE_PASSWORD_MESSAGE: 'ACCOUNT_HIDE_PASSWORD_MESSAGE',
    PROFILE_GET_USER_INFO: 'PROFILE_GET_USER_INFO',
    PROFILE_UPDATE_DETAILS: 'PROFILE_UPDATE_DETAILS',
    PROFILE_UPDATE_DETAILS_COMPLETE: 'PROFILE_UPDATE_DETAILS_COMPLETE',
    PROFILE_SAVE_PROPERTY: 'PROFILE_SAVE_PROPERTY',
    PROFILE_REMOVE_PROPERTY: 'PROFILE_REMOVE_PROPERTY',
    PROFILE_SAVE_SEARCH: 'PROFILE_SAVE_SEARCH',
    PROFILE_REMOVE_SEARCH: 'PROFILE_REMOVE_SEARCH',
    PROFILE_GET_SAVED_PROPERTIES: 'PROFILE_GET_SAVED_PROPERTIES',
    RELATED_PROPERTIES: 'RELATED_PROPERTIES',
    NEW_DEVELOPMENTS: 'NEW_DEVELOPMENTS',
    GENERATE_URL: 'GENERATE_URL',
    FETCH_PROPERTIES: 'FETCH_PROPERTIES',
    API_REQUEST: '_API_REQUEST',
    API_SUCCESS: '_API_SUCCESS',
    API_FAILURE: '_API_FAILURE',
    API_REQUEST_END: '_API_REQUEST_END',
    API_REQUEST_CANCEL: '_API_REQUEST_CANCEL',
    TRACKING_TAG: 'tracking/TAG',
    PROPERTY_SHARED: 'PROPERTY_SHARED',
    TRACKING_PROPERTY_IMPRESSIONS: 'TRACKING_PROPERTY_IMPRESSIONS',
    TRACKING_PROPERTY_CAROUSEL: 'TRACKING_PROPERTY_CAROUSEL',
    TRACKING_MARKET_PROPERTY: 'TRACKING_MARKET_PROPERTY',
    TRACKING_DOWNLOAD_PROPERTY: 'TRACKING_DOWNLOAD_PROPERTY',
    GET_AUTO_SUGGEST: 'GET_AUTO_SUGGEST',
    SET_RESULTS_PAGE: 'SET_RESULTS_PAGE',
};
var apiTrigger = function (subType) { return function (type) { return "".concat(type).concat(subType); }; };
exports.getRequestType = apiTrigger(types.API_REQUEST);
exports.getSuccessType = apiTrigger(types.API_SUCCESS);
exports.getFailureType = apiTrigger(types.API_FAILURE);
exports.getRequestEndType = apiTrigger(types.API_REQUEST_END);
exports.getRequestCancelType = apiTrigger(types.API_REQUEST_CANCEL);
exports.mySavillsTypes = {
    APPLICANT_DATA_LOADED: 'APPLICANT_DATA_LOADED',
    VIEW_SAVED_ALERT: 'PROFILE_VIEW_SAVED_SEARCH',
    TOGGLE_SAVED_ALERT: 'PROFILE_TOGGLE_ALERT_API_SUCCESS',
    VIEW_SAVED_PROPERTY: 'PROFILE_VIEW_SAVED_PROPERTY',
};
exports.default = types;
