"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.APPROVED_TYPES = void 0;
var actionTypes_1 = __importStar(require("./actionTypes"));
exports.APPROVED_TYPES = [
    actionTypes_1.default.ACCOUNT_LOGOUT,
    actionTypes_1.default.CHANGE_CATEGORY_TYPE,
    actionTypes_1.default.RESET_CATEGORY_TYPE,
    actionTypes_1.default.GET_USER_LOCATION_START,
    actionTypes_1.default.GET_USER_LOCATION_SUCCESS,
    actionTypes_1.default.MAP_INFOBOX_CLICK,
    actionTypes_1.default.MAP_INFOBOX_SHOW,
    actionTypes_1.default.MAP_RESIZE_RADIUS,
    actionTypes_1.default.MAP_SHOW_POI,
    actionTypes_1.default.NEXT_ROUTE_CHANGE,
    actionTypes_1.default.PROPERTY_TRACKING_CLICK,
    actionTypes_1.default.TRACKING_TAG,
    actionTypes_1.default.PROPERTY_SHARED,
    actionTypes_1.default.TOGGLE_PROPERTY_SHARE_MODAL,
    actionTypes_1.default.TRACKING_PROPERTY_CAROUSEL,
    actionTypes_1.default.SEARCH_BY_URL_SUCCESS,
    actionTypes_1.default.SEARCH_LOAD_SAVED,
    actionTypes_1.default.SEARCH_PROPERTIES_SUCCESS,
    actionTypes_1.default.SELECT_FILTER_VALUE,
    actionTypes_1.default.SELECT_PRIMARY_FILTER,
    actionTypes_1.default.SELECT_SEARCH_LOCATION,
    actionTypes_1.default.SESSION_DATA_LOADED,
    actionTypes_1.default.START_SESSION,
    actionTypes_1.default.SUBMIT_ARRANGE_VIEWING,
    actionTypes_1.default.SUBMIT_CONTACT_AGENT,
    actionTypes_1.default.TOGGLE_PROPERTY_ARRANGE_VIEWING_MODAL,
    actionTypes_1.default.TOGGLE_PROPERTY_CONTACT_MODAL,
    actionTypes_1.default.UPDATE_SORT_ORDER,
    actionTypes_1.default.UPDATE_PROPERTIES_TO_SHOW_MODE,
    actionTypes_1.default.TRACKING_MARKET_PROPERTY,
    actionTypes_1.default.TRACKING_DOWNLOAD_PROPERTY,
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.PROFILE_GET_USER_INFO),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.GENERATE_URL),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.ACCOUNT_LOGIN),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.ACCOUNT_REGISTER),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.ACCOUNT_UNSUBSCRIBE),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.ARRANGE_VIEWING),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.CONTACT_AGENT),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.PROFILE_REMOVE_PROPERTY),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.PROFILE_REMOVE_SEARCH),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.PROFILE_SAVE_PROPERTY),
    (0, actionTypes_1.getSuccessType)(actionTypes_1.default.PROFILE_SAVE_SEARCH),
    actionTypes_1.mySavillsTypes.APPLICANT_DATA_LOADED,
    actionTypes_1.mySavillsTypes.VIEW_SAVED_ALERT,
    actionTypes_1.mySavillsTypes.TOGGLE_SAVED_ALERT,
    actionTypes_1.mySavillsTypes.VIEW_SAVED_PROPERTY,
];
